<template>
  <b-overlay :show='isLoading' rounded='lg' opacity='0.6' spinner-variant='primary'>
    <template #overlay>
      <div class='d-flex align-items-center'>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
        <b-spinner type='grow' variant='dark'></b-spinner>
        <b-spinner small type='grow' variant='secondary'></b-spinner>
      </div>
    </template>
    <b-row class='mt-4'>
   
      <b-col>
        <b-form @submit='onSubmit' @reset='onReset'>
          <router-link class="btn btn-sm btn-dark  "  size="sm"  :to="{ path: '/currencyQuotes/display'}">
            <em class="fa fa-backward btn-sm btn-dark "></em> Back
          </router-link>

          <div class='card'>
            <div class='bg-primary p-3'>
              <h5 class='card-title mb-0 font-weight-bold'>Create New Currency Quotes</h5>
            </div>
          
            <div class='card-body'>
              <b-row>
								<b-col lg="6" class="mt-4">
									<b-card class="bg-info text-dark">(*) Kolom berlabel bintang wajib diisi.</b-card>
								</b-col>
							</b-row>
              <b-row>
              <b-col lg="6">
                <b-form-group label="Base Currency *">
                  <multiselect
                    v-model="form.base_currency"
                    label="iso4217_code"
                    track-by="id"
                    placeholder="Base Currency"
                    open-direction="bottom"
                    :options="BaseCurrencySearchOptions"
                    :searchable="true"
                    :loading="isBaseCurrencySearch"
                    :multiple="false"
                    :taggable="false"
                    @search-change="setOptionCounterCurrency('base')"
                    required
                  >
                  </multiselect>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="Counter Currency *">
                  <multiselect
                    v-model="form.counter_currency"
                    label="iso4217_code"
                    track-by="id"
                    placeholder="Counter Currency"
                    open-direction="bottom"
                    :options="CounterCurrencySearchOptions"
                    :searchable="true"
                    :loading="isCounterCurrencySearch"
                    @input="counterSelected"
                    :multiple="false"
                    :taggable="false"
                    @search-change="setOptionCounterCurrency('counter')"
                    required
                  >
                  </multiselect>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col lg="6">
                <b-form-group label='Source *'>
                  <div v-if=!this.isOthers>
                    <b-select id="source"    v-model="form.source" @input="checkOthers()" :options="SourceTypeOption" required>
                    </b-select>
                  </div>
                  <div v-else>
                    <b-form-input id='name' v-model='form.source'  step='any'
                      required>
                    </b-form-input>
                    <b-button variant="primary"  class="btn btn-sm btn-dark col-sm-auto"
                    type="button"  @click="resetOther">Reset Sources</b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col lg="6">
              <b-form-group label='Quote *'>
                <b-form-input id='quote' v-model='form.quote'  step='any' type ="number"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <div class="col-md-6">
              <b-form-group id="input-validFrom" label="Valid From *"  label-for="validFrom">
                <b-form-datepicker today-button id="validFrom"  required v-model="form.valid_from" class="mb-2"></b-form-datepicker>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group id="input-validFrom" label="Valid To *"  label-for="validFrom">
                <b-form-datepicker today-button id="validTo"  required v-model="form.valid_to" class="mb-2"></b-form-datepicker>
              </b-form-group>
            </div>

            </b-row>

          <b-row>
          <b-col>
            <b-form-group>
              <b-form-checkbox v-model='form.is_active' :value=true unchecked-value='false'> Is Active
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <label></label>
          <div class="col-md-4">
            <b-button variant='primary' type='submit'>Create Currency Quotes</b-button>
          </div>
        </b-row>
      </div>
    </div>
  </b-form>
</b-col>
</b-row>
</b-overlay>
</template>

<script>
import moment from "moment"
import { mapState, mapActions } from 'vuex';
import constant from "../../store/constant";
export default {
  name: 'createCurrencyQuotes',
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(today);
    return {
      debounce: null,
      id: null,
      isOthers:false,
      form: {
        min:minDate,
        base_currency:null,
        counter_currency:null,
        source:null,
        is_active:true,
        quote:null,
        valid_from:'',
        valid_to:'',
      },range: {
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
      },
      masks: {
          input: "YYYY-MM-DD"
      },
      isBaseCurrencySearch: false,
      isCounterCurrencySearch: false,
      SourceTypeOption: [
				{ value: constant.SOURCE_CURRENCY.GOOGLE.toLowerCase(), text: "Google" },
        { value: constant.SOURCE_CURRENCY.YAHOO.toLowerCase(), text: "Yahoo" },
        { value: constant.SOURCE_CURRENCY.FINANCE_ADMIN.toLowerCase(), text: "Finance Admin" },
        { value: constant.SOURCE_CURRENCY.OTHERS.toLowerCase(), text: "Others" },
      ],
      BaseCurrencySearchOptions: [], 
      CounterCurrencySearchOptions: [],
    };
  },
  computed: {
    ...mapState({
      totalRows: (state) => state.currencyQuotes.totalRows,
      item_id :(state) => state.currencyQuotes.item_id,
      isLoading : (state) => state.currencyQuotes.isLoading,
      isError : (state) => state.currencyQuotes.isError,
      errorMessage: (state) => state.currencyQuotes.errorMessage,
      successMessage: (state) => state.currencyQuotes.successMessage,
      refresh: (state) => state.currencyQuotes.refresh,
    }),
  },
  watch: {
    successMessage: function() {
      console.log('this is successr message', this.successMessage)
      if (!this.successMessage) return;
      this.messageAlert('success', this.successMessage);
      return this.$router.push({
        path: `/currencyQuotes/display`,
      })
    },
    errorMessage: function() {
      console.log('this is error message', this.errorMessage)
      if (!this.errorMessage) return;
      return this.baseAlert({
        icon: 'error',
        title: 'Error',
        text: `${this.errorMessage}`,
      });
    },
  },
  created() {
    this.setOptionCounterCurrency('all');
  },
  mounted() {},
  methods: {
  ...mapActions("currencyQuotes", ["createCurrencyQuotes"]),
  ...mapActions("currency", ["fetchCurrency"]),
  checkOthers(){
    let sources = this.form.source;
    if(sources == constant.SOURCE_CURRENCY.OTHERS.toLowerCase()){
      this.isOthers = true;
    } else{
      this.isOthers = false;
    }
  },resetOther(){
    this.isOthers = false;
  },
  baseAlert(data) {
      this.$swal({
        icon: data.icon,
        title: data.title,
        text: data.text,
    })
  },
  onSubmit(event) {
    event.preventDefault();
    const data = this.form;
    if(data.base_currency == null){
      return this.baseAlert({
        icon: 'error',
        title: 'Error',
        text: 'Base Currency Must Be Inputed',
      });
    }
    if(data.counter_currency == null){
      return this.baseAlert({
        icon: 'error',
        title: 'Error',
        text: 'Counter Currency Must Be Inputed',
      });
    }
    if(data.valid_from == null || data.valid_from == ''){
      return this.baseAlert({
        icon: 'error',
        title: 'Error',
        text: 'Valid From Must Be Inputed',
      });
    }
    if(data.valid_to == null || data.valid_to == ''){
      return this.baseAlert({
        icon: 'error',
        title: 'Error',
        text: 'Valid To Must Be Inputed',
      });
    }
		const d1 = new Date(data.valid_from);
    const d2 = new Date(data.valid_to);
    if(d1>d2) {
      return this.baseAlert({
        icon: 'error',
        title: 'Error',
        text: 'Valid To Should Be Bigger Than Valid From',
      });
    }
    let isActive = false;
    if(data.is_active){
      isActive = data.is_active;
    }
    if(data.quote<=0) {
      return this.baseAlert({
        icon: 'error',
        title: 'Error',
        text: 'Quotes should not be smaller than zero',
      });
    }
    const payload = {
      base_currency: data.base_currency.iso4217_code,
      counter_currency:data.counter_currency.iso4217_code,
      source:data.source,
      quote:data.quote,
      valid_from : data.valid_from,
      valid_to : data.valid_to,
      is_active:isActive,
    }
    this.createCurrencyQuotes(payload);
  },  async setOptionCounterCurrency(type = 'all'){
    if(type=='base'|| type=='all') {
      this.isBaseCurrencySearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.fetchCurrency({ page: 1, limit: 100 })
					.then((response) => {
						this.BaseCurrencySearchOptions = response.data.data.rows;
					})
        }, 1200);
      this.isBaseCurrencySearch = false;
    } if(type=='counter' || type == 'all'){
      this.isCounterCurrencySearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.fetchCurrency({ page: 1, limit: 100 })
					.then((response) => {
						this.CounterCurrencySearchOptions = response.data.data.rows;
				
					})
        }, 1200);
      this.isCounterCurrencySearch = false;
    }
  }, counterSelected () {
    
    if (this.form.base_currency.iso4217_code == this.form.counter_currency.iso4217_code) {
      this.counter_currency = null;
      return this.baseAlert({
          icon: 'error',
          title: 'Error',
          text: 'Base Currency And Counter Currency Must not same',
        });
      }
  },
  onReset() {},
    messageAlert(icon, title, timer=3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
  },nameWithISO({ name, iso }) {
      return `${iso} - ${name}`
  },reset() {
      Object.assign(this.$data, this.$options.data.call(this));
  },
  }
};

</script>
